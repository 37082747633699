<template>
  <div class="social-wrapper mt-4 d-flex flex-wrap">
    <v-btn @click="showIcons = !showIcons" text color="#000000">Delen:<v-icon dark tile outlined class="ml-1">mdi-share-variant</v-icon></v-btn>
    <transition name="socialAnim">
      <v-btn-toggle borderless group class="social-icon-group" v-show="showIcons">
        <v-btn icon x-large target="_blank" :href="facebookShare"><v-icon dark color="indigo">mdi-facebook</v-icon></v-btn>
        <v-btn icon x-large target="_blank" :href="twitterShare"><v-icon dark color="#1b95e0">mdi-twitter</v-icon></v-btn>
        <v-btn icon x-large target="_blank" :href="linkedinShare"><v-icon dark color="#0073b1">mdi-linkedin</v-icon></v-btn>
        <v-btn icon x-large target="_blank" :href="whatsAppShare"><v-icon dark color="#25D366">mdi-whatsapp</v-icon></v-btn>
        <v-btn icon x-large target="_blank" :href="mailShare"><v-icon dark color="#000000">mdi-email</v-icon></v-btn>
        <!-- Copy url -->
        <v-tooltip v-model="showToolTip" :open-on-click="true" :open-on-hover="false" :close-delay="500" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large @click="copyToClipBoard" id="copyUrlWrapper" v-on="on">
              <v-icon dark color="#000000">mdi-content-copy</v-icon>
              <input type="hidden" id="copyUrl" :value="voucherUrl" />
            </v-btn>
          </template>
          <span>URL gekopieerd!</span>
       </v-tooltip>
      </v-btn-toggle>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SocialShare',
  props: {
    voucher:Object
  },
  data(){
    return {
      domainUrl:'',
      voucherUrl:'',
      showIcons:false,
      showToolTip:false,
      facebookShare:'',
      twitterShare:'',
      linkedinShare:'',
      whatsAppShare:'',
      mailShare:''
    }
  },
  mounted() {
    this.domainUrl = window.location.origin; // var is being watched
  },
  watch: {
    domainUrl: function() {
      // when domainUrl is known
      this.voucherUrl = this.domainUrl + this.voucher.url_alias + '/stap1'; // complete voucher Url, domain + remaining url from api
      this.facebookShare = encodeURI('https://www.facebook.com/sharer.php?u=' + this.voucherUrl);
      this.twitterShare = encodeURI('https://twitter.com/intent/tweet?url=' + this.voucherUrl + '&text=' + this.voucher.voucher_header + ' - ' + this.voucher.voucher_description);
      this.linkedinShare = encodeURI('https://www.linkedin.com/shareArticle?mini=true&url=' + this.voucherUrl + '&title=' + this.voucher.voucher_header + '&summary=' + this.voucher.voucher_header + ' - ' + this.voucher.voucher_description);
      this.whatsAppShare = encodeURI('https://wa.me/?text=' + this.voucher.voucher_header + ': ' + this.voucherUrl);
      // avoided encoding mail share link, new enter lines wont work.
      this.mailShare = 'mailto:?subject=Ik wil deze voucher met je delen: ' + this.voucher.voucher_header + ' - ' + this.voucher.voucher_description + '&body=' + this.voucher.voucher_header + '%0D%0A' + this.voucher.voucher_description + '%0D%0A%0D%0ABekijk de voucher op:%0D%0A' + this.voucherUrl;
    }
  },
  methods: {
    copyToClipBoard() {
      var currentVoucherUrl = document.getElementById("copyUrl");
      currentVoucherUrl.type = 'text'; // show field, so we can select url in the input
      currentVoucherUrl.select();
      currentVoucherUrl.setSelectionRange(0, 99999); // for mobile
      document.execCommand("copy");
      currentVoucherUrl.type = 'hidden'; // hide the field again after copying
    }
  }
};
</script>

<style lang="scss">
  .social-icon-group {
    background-color: #ffffff;
    flex-flow: row wrap;
  }
  .socialAnim-enter-active {
    animation: social-in 0.25s;
  }
  .socialAnim-leave-active {
    animation: social-in 0.3s reverse;
  }
  // animation for social wrapper
  @keyframes social-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
</style>
