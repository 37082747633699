<template>
  <v-card class="voucher-preview-inner-wrapper" :elevation="0" tile>
    <!-- START TILE LAYOUT -->
    <div class="voucher-tile-wrapper">
      <div class="voucher-tile-inner-wrapper pa-3" :style="{'backgroundColor': voucherBackgroundColor}">
        <div class="voucher-text-bg">
          <img alt="" src="../../assets/voucher.png" :style="iconColor"/>
        </div>
        <div class="voucher-data-container">
          <!--<div class="voucher-text-bg"><img alt="Download" src="../../assets/voucher.png"/></div>-->
          <div class="align-content-start voucher-title pr-12" :style="'color:' + voucherTextColor + ';'" v-html="voucher.voucher_header"></div>
          <div class="voucher-description" :style="'color:' + voucherTextColor + ';'" v-html="voucher.voucher_description"></div>
          <div class="voucher-sub-description text-left voucher-text-color mb-1" :style="'color:' + voucherTextColor + ';'" v-html="voucher.voucher_pay_off"></div>
          <!-- logo -->
          <div class="dealerdata-logo" :style="'width:' + dealerConfigData.dealer_logo.form_voucher_width + 'px;'"><img class="voucher-icon" :src="'https://' + voucher.voucher_icon" /></div>
          <div class="voucher-footer d-flex flex-wrap flex-row-reverse justify-end">
            <div class="voucher-action-label pa-1 mt-2" :style="'color:' + voucher.action_panel_text_color + ';background-color:' + voucher.action_panel_background_color + ';'" v-html="voucher.action_panel_text"></div>
            <div class="dealerdata-name mt-2 pb-1 d-flex align-end mr-auto pr-2" :style="'color:' + voucherTextColor + ';'" v-html="dealerJSON.config.dealer_name"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- END TILE LAYOUT -->
    <SocialShare :voucher="voucher" v-if="showShareButtons" />
  </v-card>
</template>

<script>
import SocialShare from '@/components/voucherForm/SocialShare.vue';
import {Color, Solver} from '@/assets/js/ColorSolver';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'VoucherForm',
  components: {
    SocialShare
  },
  props: {
    voucher:Object,
    voucherBackgroundColor:String,
    voucherTextColor:String,
    showShareButtons:Boolean
  },
  data() {
    return {
      iconColor:''
    }
  },
  mounted() {
    const rgb = this.hexToRgb(this.voucherTextColor);
    if (rgb.length !== 3) {
      alert('Invalid format!');
      return;
    }
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();
    this.iconColor = result.filter;
  },
  methods: {
    hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
        : null;
    }
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData // get data from store
  })
};
</script>

<style lang="scss">
  // max-width on inner-wrapper in TILES LAYOUT
  .voucher-preview-inner-wrapper {
    width: 330px;
    //min-width: 330px;
    max-width: 100%;
    .voucher-data-container {
      position: relative;
    }
  }
  // inner wrapper
  .voucher-preview-inner-wrapper.v-card {
    position: relative;
    z-index: 1;
    background-color: transparent !important;
    // general styles
    .voucher-title {
      // override
      word-break: normal;
      word-wrap: normal;
      font-size: 1.5rem; //1.425rem;
      line-height: 1.875rem;
      font-weight: 300;
    }
    .voucher-description {
      // override
      font-size: 1.375rem; //1.625rem;
      line-height: 1.875rem;
      font-weight: 900;
      overflow-wrap:normal;
    }
    .voucher-sub-description {
      min-height: 24px;
    }
    .dealerdata-logo {
      position: absolute;
      top:0;
      right:0;
      img {
        width: 100%;
        display: block;
      }
    }
    .voucher-icon-wrapper {
      width: 100%;
      height: 100%;
      mask-repeat: no-repeat;
      background-color: #ffffff; // coloring of icon
      opacity: 0.2;
      z-index: 0;
    }
    // START TILE LAYOUT
    .voucher-tile-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      // START inner wrapper
      .voucher-tile-inner-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        /*
        .voucher-text-bg {
          position: absolute;
          top:0;
          right:0;
          height: 100%;
          text-align: right;
          opacity: 0.2;
          padding: 3px 0 3px;
          width: 100%;
          z-index: 0;
          img {
            height: 100%;
            max-height: 320px;
            position: absolute;
            top:50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
        */
        .voucher-text-bg {
          position: absolute;
          bottom: -2px;
          left: 0;
          max-width: 100%;
          opacity: 0.2;
          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
    // END TILE LAYOUT
  }
</style>
