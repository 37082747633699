<template>
  <v-card id="VoucherForm" class="app-bar-padding-top" v-if="currentVoucher"><!-- only show below when we have a voucher object to download -->
    <!-- top bar -->
    <CustomAppBar v-if="dealerJSON" @MainMenuClick="$emit('MainMenuClick')" v-on="$listeners"/>
    <!-- top bar -->
    <v-stepper v-model="currentStep" class="ma-0 pa-0 voucherBackgroundColor" style="box-shadow:none">
      <v-stepper-header class="voucher-stepper-header" :style="{'box-shadow':'none'}">
        <v-stepper-step :complete="currentStep > 1" step="1">
          Stap 1
          <small>Uw gegevens</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2">
          Stap 2
          <small>Bevestig uw gegevens</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep == 3" step="3">
          Stap 3
          <small>Ontvang uw voucher</small>
        </v-stepper-step>
      </v-stepper-header>
      <v-divider></v-divider>
      <v-stepper-items class="mt-4">
        <!-- START STEP 1 -->
        <v-stepper-content step="1" class="voucherFormStepperContent ma-0 pa-0">
          <v-list three-line subheader class="voucherBackgroundColor">
            <v-list-item>
              <div class="intro-wrapper d-flex flex-wrap flex-sm-nowrap">
                <!-- START VOUCHER PREVIEW DESK -->
                <div class="voucher-preview step-one-desk pt-2 d-none d-sm-block">
                  <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                </div>
                <!-- END VOUCHER PREVIEW DESK -->
                <div class="voucher-form voucher-form-step1">
                  <div class="voucher-text pl-2 pr-2">
                    <div class="d-flex justify-space-between"><h2 class="mb-10">Wilt u direct profiteren van deze voucher?</h2><v-btn aria-label="Sluiten" dark @click="voucherFormAction('close')" color="black" text class="pa-0 text-capitalize close-form"><v-icon aria-hidden="true" class="mt-1" large>mdi-close-box</v-icon></v-btn></div>
                    <!-- START VOUCHER PREVIEW MOBILE -->
                    <div class="voucher-preview step-one-mobile mb-5 d-block d-sm-none">
                      <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                    </div>
                    <!-- END VOUCHER PREVIEW MOBILE -->
                    <h4 class="body-1 font-weight-bold mb-5">Volg deze 3 gemakkelijke stappen!</h4>
                    <p class="body-1 font-weight-medium mb-5">
                      1. Vul uw gegevens in
                      <br>
                      2. Bevestig uw gegevens
                      <br>
                      3. Print de voucher uit en neem deze mee naar onze werkplaats (of toon de bevestiging op uw mobiele telefoon)
                    </p>
                    <p class="body-1 mb-5" v-if="currentVoucher.general_terms">
                      <span class="font-weight-bold">*Voorwaarden:</span> {{currentVoucher.general_terms}}
                    </p>
                  </div>
                  <div class="form-wrapper">
                    <div class="mb-3 pt-2 pl-2 pr-2 "><h2>Stap 1:<span class="font-weight-regular"> Uw gegevens</span></h2></div>
                    <v-form ref="voucherFormRef" v-model="valid" lazy-validation>
                      <v-container class="pa-0">
                        <div class="pl-2 pr-2">
                          <v-radio-group v-model="aanhef" class="d-flex flex-nowrap ma-0 pb-2 aanhef-field" :rules="aanhefRules" row>
                            <v-radio label="Dhr." color="black" value="Heer">
                              <template v-slot:label>
                                <div style="color:#000000;">Dhr.</div>
                              </template>
                            </v-radio>
                            <v-radio color="black" value="Mevrouw">
                              <template v-slot:label>
                                <div style="color:#000000;">Mevr.</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                        <div class="d-flex flex-wrap voucherField-wrapper">
                          <v-text-field class="voucherField" label="Voorletters*" v-model="voorletters" :rules="voorlettersRules" hide-details="auto" required outlined></v-text-field>
                          <v-text-field class="voucherField" label="Achternaam*" v-model="achternaam" :rules="achternaamRules" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div class="d-flex flex-wrap voucherField-wrapper">
                          <div class="voucherField">
                            <v-text-field v-if="currentVoucher.licenseplate_required == 0" class="voucherField voucher-kenteken pa-0" label="Kenteken" v-model="kenteken" @blur="getCarData()" hide-details="true" required outlined ></v-text-field>
                            <v-text-field v-if="currentVoucher.licenseplate_required == 1" class="voucherField voucher-kenteken pa-0" :class="{ 'kenteken--error': kentekenApproved == false}" label="Kenteken*" v-model="kenteken" @blur="getCarData()" hide-details="auto" required outlined :rules="kentekenRules"></v-text-field>
                            <p v-if="identifiedCarMsg && kenteken != ''" class="ma-0 pl-3 pr-3 caption" :class="{ 'red--text': kentekenApproved == false}"> {{ identifiedCarMsg }}</p>
                          </div>
                          <v-text-field class="voucherField" label="Telefoonnummer" v-model="telefoonnummer" hide-details="true" type="tel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' outlined></v-text-field>
                        </div>
                        <div class="d-flex flex-wrap voucherField-wrapper">
                          <v-text-field class="voucherField" label="E-mailadres*" v-model="email" hide-details="auto" :rules="emailRules" required outlined></v-text-field>
                        </div>
                        <div class="d-flex flex-wrap voucherField-wrapper pl-2 pr-2 mb-6" v-if="currentVoucher.activate_quotation == 1">
                          <v-checkbox v-model="toestemmingOfferte" class="align-self-start ma-0" hide-details="auto">
                            <template v-slot:label>
                              <div style="color:#000000;" v-html="currentVoucher.quotation_text"></div>
                            </template>
                          </v-checkbox>
                        </div>
                        <v-slide-y-transition>
                          <div class="pl-2 pr-2" v-if="showWarningMesg">
                            <v-alert dense type="warning">
                              {{warningMesg}}
                            </v-alert>
                          </div>
                        </v-slide-y-transition>
                        <div>
                          <template v-if="toestemmingOfferte">
                            <v-row>
                              <v-col>
                                <div style="color:#000000;" v-html="currentVoucher.invoice_text"></div>
                              </v-col>
                            </v-row>
                            <v-row v-if="currentVoucher.invoice_text">
                              <v-col>
                                <v-textarea solo v-model="invoiceNote" label="Vul hier uw antwoord in."></v-textarea>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                        <div class="voucherButton-wrapper step-1 pl-2 pr-2">
                          <v-btn aria-label="Volgende stap" dark @click="voucherFormAction('stepTwo')" class="mt-1 mb-4 d-block voucherButton buttonStyle">Ga naar stap 2</v-btn>
                          <a @click="voucherFormAction('close')" class="mb-4 mr-4 d-block voucherButton buttonStyleLink font-weight-bold">Annuleren</a>
                        </div>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-stepper-content>
        <!-- END STEP 1 -->
        <!-- START STEP 2 -->
        <v-stepper-content step="2" class="voucherFormStepperContent ma-0 pa-0">
          <v-list three-line subheader class="voucherBackgroundColor">
            <v-list-item class="justify-center">
              <div class="mobVoucher2Class">
                <div class="pl-2 pr-2 d-flex flex-nowrap intro-wrapper justify-start">
                  <div class="voucher-form voucher-form-step2 pr-12 pb-4">
                    <div class="voucher-text pl-2 pr-2">
                      <h2 class="mb-3">Stap 2: <span class="font-weight-regular">Bevestig uw gegevens</span></h2>
                      <v-simple-table class="bevestigVoucherGegevens voucherBackgroundColor pb-6">
                        <template v-slot:default>
                          <tbody>
                          <tr>
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">Aanhef</td>
                            <td class="pt-4 pl-2">{{aanhef}}</td>
                          </tr>
                          <tr>
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">Voorletters</td>
                            <td class="pt-4 pl-2">{{voorletters}}</td>
                          </tr>
                          <tr>
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">Achternaam</td>
                            <td class="pt-4 pl-2">{{achternaam}}</td>
                          </tr>
                          <tr v-if="telefoonnummer">
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">Telefoon</td>
                            <td class="pt-4 pl-2">{{telefoonnummer}}</td>
                          </tr>
                          <tr v-if="kenteken">
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">Kenteken</td>
                            <td class="pt-4 pl-2 text-uppercase">{{kenteken}}</td>
                          </tr>
                          <tr>
                            <td class="pt-4 pl-0 pr-0 font-weight-bold">E-mailadres</td>
                            <td class="pt-4 pl-2">{{email}}</td>
                          </tr>
                          <tr v-if="toestemmingOfferte == true">
                            <td class="pt-4 pl-0" colspan="2">{{ currentVoucher.quotation_text }}</td>
                          </tr>
                          <tr v-if="invoiceNote">
                            <td class="pt-4 pl-0" colspan="2">{{ invoiceNote }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <p class="body-1 font-weight-medium">
                        <v-btn aria-label="wijzigen" dark @click="voucherFormAction('backToStepOne')" color="black" text class="pa-0 text-capitalize"><v-icon aria-hidden="true" class="mr-1" small>mdi-pencil</v-icon><span style="text-decoration:underline;">Wijzigen</span></v-btn>
                      </p>
                      <v-form ref="confirmVoucherRef" v-model="valid" lazy-validation>
                        <v-container class="pa-0">
                          <v-checkbox :rules="bevestigingRules" color="black" hide-details="auto">
                            <template v-slot:label><div style="color:black;">Helemaal goed! Ik heb m'n gegevens gecontroleerd.</div></template>
                          </v-checkbox>
                        </v-container>
                        <div id="privacyCheckbox" class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper" v-if="dealerJSON">
                          <v-checkbox v-model="acceptsPrivacyConditions" :rules="privacyRules" class="align-self-start" hide-details="auto">
                            <template v-slot:label>
                              <div style="color:#000000;">
                                Ik ga akkoord met de
                                <a target="_blank" :href="dealerJSON.config.privacy_url" @click.stop>privacy voorwaarden.</a>
                              </div>
                            </template>
                          </v-checkbox>
                        </div>
                      </v-form>
                      <div v-if="showErrorMesg">
                        <v-alert dense type="error">
                          {{errorMesg}}
                        </v-alert>
                      </div>
                    </div>
                    <!-- START VOUCHER PREVIEW MOBILE -->
                    <div class="voucher-preview step-two mb-5 pl-2 pr-2 d-block d-sm-none">
                      <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                      <p class="body-2 pt-5" v-if="currentVoucher.general_terms">
                        <span class="font-weight-bold">*Voorwaarden</span><br> {{currentVoucher.general_terms}}
                      </p>
                    </div>
                    <!-- END VOUCHER PREVIEW MOBILE -->
                  </div>
                  <!-- START VOUCHER PREVIEW DESK -->
                  <div class="voucher-preview step-two pt-6 d-none d-sm-block">
                    <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                    <p class="max-voucher-width pt-5" v-if="currentVoucher.general_terms">
                      <span class="font-weight-bold">*Voorwaarden</span><br> {{currentVoucher.general_terms}}
                    </p>
                  </div>
                  <!-- END VOUCHER PREVIEW DESK -->
                </div>
                <div class="d-flex voucherButton-wrapper pl-2 pr-2 d-flex flex-wrap">
                  <v-btn aria-label="Versturen" dark @click="voucherFormAction('send')" class="mb-4 mr-4 d-block voucherButton buttonStyle">Verstuur deze voucher</v-btn>
                  <v-btn aria-label="Verstuur en plan direct afspraak in" v-if="dealerJSON && (currentVoucher.schedule_workplace == 1)" dark @click="voucherFormAction('werkplaats')" class="mb-4 mr-4 d-block voucherButton buttonStyle voucherWerkplannerButton">Verstuur deze voucher + plan direct afspraak in</v-btn>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-stepper-content>
        <!-- END STEP 2 -->
        <!-- START STEP 3 -->
        <v-stepper-content step="3" class="voucherFormStepperContent ma-0 pa-0">
          <v-list three-line subheader class="voucherBackgroundColor">
            <v-list-item>
              <div class="pl-2 pr-2 d-flex flex-nowrap intro-wrapper justify-start">
                <div class="voucher-form voucher-form-step3 pr-12 pb-4">
                  <div class="voucher-text pl-2 pr-2">
                    <h2 class="mb-3"><span class="font-weight-regular">Uw voucher van</span> {{dealerJSON.config.dealer_name}}</h2>
                  </div>
                  <p class="body-1 pl-2 pr-2">
                    {{dealerJSON.config.confirmation_message}}
                  </p>
                  <div class="d-flex voucherButton-wrapper pl-2 pr-2 d-flex flex-wrap flex-sm-nowrap">
                    <v-btn aria-label="Sluiten" dark @click="voucherFormAction('close');emitApiCall();" class="mt-4 mb-4 mr-4 d-block voucherButton buttonStyle">Sluiten</v-btn>
                  </div>
                  <!-- START VOUCHER PREVIEW MOBILE -->
                  <div class="voucher-preview step-two mb-5 pl-2 pr-2 d-block d-sm-none">
                    <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                    <p class="body-2 pt-5" v-if="currentVoucher.general_terms">
                      <span class="font-weight-bold">*Voorwaarden</span><br> {{currentVoucher.general_terms}}
                    </p>
                  </div>
                  <!-- END VOUCHER PREVIEW MOBILE -->
                </div>
                <!-- START VOUCHER PREVIEW DESK -->
                <div class="voucher-preview step-two pt-2 d-none d-sm-block">
                  <voucherPreview :showShareButtons="true" :voucher="currentVoucher" :voucherBackgroundColor="getVoucherColor(currentVoucher)[0]" :voucherTextColor="getVoucherColor(currentVoucher)[1]"/>
                  <p class="max-voucher-width pt-5" v-if="currentVoucher.general_terms">
                    <span class="font-weight-bold">*Voorwaarden</span><br> {{currentVoucher.general_terms}}
                  </p>
                </div>
                <!-- END VOUCHER PREVIEW DESK -->
              </div>
            </v-list-item>
          </v-list>
        </v-stepper-content>
        <!-- END STEP 3 -->
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import axios from 'axios';
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import voucherPreview from '@/components/voucherForm/voucherPreview.vue';
import { uuid } from "vue-uuid";
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'VoucherForm',
  props: {
    currentVoucher:Object
  },
  components: {
    voucherPreview,
    CustomAppBar
  },
  data: () => ({
    uuid: uuid.v1(),
    apiConfig: {headers: {'Authorization':'Bearer ' + process.env.VUE_APP_API_TOKEN}},
    httpProtocol: window.location.protocol + '//',
    formVisibility: true,
    currentStep:1,
    valid: true,
    aanhef:'Heer',
    voorletters:'',
    achternaam:'',
    telefoonnummer:'',
    kenteken:'',
    identifiedCarMsg: '',
    kentekenApproved: null,
    email:'',
    domainUrl:'',
    warningMesg:'',
    invoiceNote:'',
    warningMesgTimer: null,
    toestemmingOfferte: false,
    showWarningMesg: false,
    errorMesg:'',
    showErrorMesg: false,
    acceptsPrivacyConditions: false, // checkbox at step 2 of form process
    aanhefRules: [
      v => !!v || 'Aanhef is verplicht'
    ],
    voorlettersRules: [
      v => !!v || 'Voorletters zijn verplicht'
    ],
    achternaamRules: [
      v => !!v || 'Achternaam is verplicht'
    ],
    kentekenRules: [
      v => !!v || 'Kenteken is verplicht'
    ],
    emailRules: [
      v => !!v || 'E-mail is verplicht',
      v => /.+@.+\..+/.test(v) || 'E-mail moet geldig zijn',
    ],
    bevestigingRules: [
      v => !!v || 'Bevestiging is verplicht'
    ],
    privacyRules: [
      v => !!v || 'U moet akkoord gaan met de privacy voorwaarden'
    ]
  }),
  watch: {
    '$route' (to, from) {
      // react to route changes...
      // if you press back when on last step (3), then you go back to overview page. Your only option on step 3 was to close page.
      if (from.meta.currentStep === 3) {
        if(sessionStorage.getItem('pOrigin')) {
          // if value is set to home
          if(sessionStorage.getItem('pOrigin') == 'home') {
            this.$router.push({name: 'home'}) // go to home page
          }
          else {
            this.$router.push({path: '/' + sessionStorage.getItem('pOrigin')});
          }
        }
        else {
          // if nothing is availble, just go to home page
          this.$router.push({name: 'home'}) // go to home page
        }
      } else {
        this.currentStep = parseInt(this.$route.meta.currentStep); // go to steps set in routes (router/index.js);
      }
      return to, from; // if to and from is not used at all, vue will show warning error; hence this return line;
    },
    kenteken() {
      // when license data value changes in field. We clean up the custom error message.
      this.identifiedCarMsg = '';
    },
    showWarningMesg() {
      clearTimeout(this.warningMesgTimer); // reset timer to prevent weird jumps of show/hide message.
      this.warningMesgTimer = setTimeout(()=>{this.showWarningMesg = false}, 3000);
    }
  },
  mounted() {
    this.getVoucherCategoryData(); // check if voucher is from excluded category, to set its return to overviewpage.
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData // get data from store
  }),
  methods: {
    getVoucherCategoryData() {
      var currentCategoryArray = this.dealerJSON.categories.filter(item => item.id === this.currentVoucher.category_id);
      // incase the voucher is approached directly through the url. We won't know from which page (category page or home page) the voucher is approached.
      // In this case, if the voucher is directly approached but also is from an excluded category, we want to set its category so that visitors can go "back" to this category.
      if (currentCategoryArray[0].exclude_on_homepage == 1) {
        sessionStorage.setItem('pOrigin', currentCategoryArray[0].url_alias); // save page origin to session storage
      }
    },
    getVoucherColor(object) {
      // FUNCTION: get the voucher color.
      // go through the categories
      for (var i = 0; i < this.dealerJSON.categories.length;i++) {
        if (object.category_id === this.dealerJSON.categories[i].id) {
          // apply found color to this.categoryColor
          var backgroundColor = this.dealerJSON.categories[i].background_color;
          var textColor = this.dealerJSON.categories[i].text_color;
          return [backgroundColor, textColor];
        }
      }
    },
    getCarData() {
      return new Promise(resolve => {
        let licensePlateClean = this.kenteken.replace(/-/g, '').toUpperCase();
        let isContained = false;
        if (this.kenteken != '') {
          axios.get(this.dealerConfigData.api.kenteken + "?kenteken=" + licensePlateClean,
              {headers: {'X-App-Token': process.env.VUE_APP_RDW_TOKEN}})
              .then((response) => {
                const merkUpper = response.data[0].merk.toUpperCase();
                const vehicleType = response.data[0].voertuigsoort;

                // Check voor VW companycar
                if (vehicleType === 'Bedrijfsauto' && this.isCompanyVwVehicle(merkUpper, vehicleType)) {
                   isContained = true;
                }
                else {
                    isContained = this.dealerConfigData.brands.some(brand => brand.toUpperCase() === merkUpper);
                }

                if (isContained) {
                  this.identifiedCarMsg = response.data[0].merk + " " + response.data[0].handelsbenaming;
                  this.kentekenApproved = true;
                } else {
                  this.identifiedCarMsg = "Helaas, uw " + response.data[0].merk + " " + response.data[0].handelsbenaming + " wordt niet ondersteund door ons.";
                  this.kentekenApproved = false;
                }
                resolve(this.kentekenApproved)
              }).catch((error) => {
            if (this.currentVoucher.licenseplate_required == 1 || (this.currentVoucher.licenseplate_required == 0 && this.kenteken != '')) {
              this.identifiedCarMsg = "Kenteken is incorrect.";
            }
            this.kentekenApproved = false;
            resolve(this.kentekenApproved)
            return error; // eslint will throw an error if you do use variable
          })
        } else if (this.kenteken == '' && this.currentVoucher.licenseplate_required == 0) {
          this.kentekenApproved = true;
          resolve(this.kentekenApproved)
        } else {
          this.kentekenApproved = false;
          resolve(this.kentekenApproved)
        }
      });
    },
    isCompanyVwVehicle(brand, type){
      if (type === 'Bedrijfsauto' && brand === 'VOLKSWAGEN') {
        return true;
      }

      return false;
    },
    emitApiCall() {
      this.$emit('make-api-call');
    },
    async voucherFormAction(state) {
      // get the class of the dialog that appears when having to fill out a form, it seems i can't set an id on it. Hence referring to ClassName in function
      switch(state) {
        case 'close':
          // when you want to cancel current voucherForm
          this.$refs.voucherFormRef.resetValidation(); // disable any warnings on required fields, but we keep the fields filled incase visitor wants to try different voucher
          this.$refs.confirmVoucherRef.reset(); // Reset checkbox on confirm page
          this.showWarningMesg = false; // verberg error melding als formulier wordt gesloten
          window.scrollTo(0,0);

          // if sesstionStorage item pOrigin is available at all
          if(sessionStorage.getItem('pOrigin')) {
            // if value is set to home
            if(sessionStorage.getItem('pOrigin') == 'home') {
              this.$router.push({name: 'home'}) // go to home page
            }
            else {
              this.$router.push({path: '/' + sessionStorage.getItem('pOrigin')});
            }
          }
          else {
            // if nothing is availble, just go to home page
            this.$router.push({name: 'home'}) // go to home page
          }
          break;
        case 'backToStepOne':
          this.showWarningMesg = false; // Hide error alert incase this function went through else statement first.
          this.currentStep = 1;
          this.$router.push({path: this.currentVoucher.url_alias + "/stap1"}); // change url to step 1
          this.$refs.confirmVoucherRef.reset(); // Reset checkbox on confirm page
          window.scrollTo(0,0);
          break;
        case 'stepTwo':
          // do kentekenCheck one more time
          await this.getCarData(); // wait for this kentekenCheck is complete (Promise) before going to next line.
          // when you filled in the form on step 1, form will do a validation check on required fields before continuing to step 2
          if (this.$refs.voucherFormRef.validate() && this.kentekenApproved == true) {
            // if all fields are validated, go to step two of form
            this.currentStep = 2; // go to confirmation page
            this.$router.push({path: this.currentVoucher.url_alias + "/stap2"}); // change url to step 2
            window.scrollTo(0,0);
            this.showWarningMesg = false; // Hide error alert incase this function went through else statement first.
          } else {
            this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
            this.warningMesg = "Enkele verplichte velden zijn niet of onjuist ingevuld."
          }
          break;
        case 'send':
        case 'werkplaats':
          if (this.kenteken == '') {
            // if kenteken is empty, change value to '-'
            this.kenteken = '-'; // mail template checks for '-'.
          }
          if (state == 'werkplaats' && this.$refs.confirmVoucherRef.validate()) {
            var visitorData = {
              "aanhef": this.aanhef,
              "voorletters": this.voorletters,
              "achternaam": this.achternaam,
              "telefoonnummer": this.telefoonnummer,
              "offerte notitie": this.invoiceNote,
              "email": this.email,
              "kenteken": this.kenteken,
              "voucherId": this.currentVoucher.voucher_id,
              "toestemmingOfferte": this.toestemmingOfferte
            }
            this.$router.push({name: 'planner-voertuig', params: {vData: visitorData}});
          } else if (this.$refs.confirmVoucherRef.validate()) {
            // On confirmation page, send form when everything looks good.
            this.$router.push({path: this.currentVoucher.url_alias + "/bedankt"}); // change url to step 3
            this.sendVoucher(this.email, true, false, ''); // perform send function to visitor

            // check if dealer wants to receive confirmation mail if a visitor has requested voucher.
            if(this.dealerJSON.config.confirmation_email) {
              //var dealerConfirmMesg = this.dealerJSON.config.mail_plan_it_intro_text // set the message for e-mail confirmation, set as parameter below
              this.sendVoucher(this.dealerJSON.config.confirmation_email, false, true, ''); // perform send function to dealer
            }
          }
          break;
      }
    },
    sendVoucher(receiverAdress, endFormAction, isDealerMail, dealerConfirmMesg) {
      var voucherReceivers = []; //variable to send to receiver(s) of vouchermail

      // if receiverAdress contains comma's (if its an array)
      if (receiverAdress.includes(",")) {
        var tempArray = receiverAdress.split(","); // split string in array determined by comma
        var result = [];

        for (var i = 0;i < tempArray.length;i++) {
          result.push(tempArray[i].split(" ").join("")); // remove spaces
        }
        // var string = receiverAdress.split(" ").join(""); // trim spaces and rejoin
        voucherReceivers = result; // pass result to common variable
      } else {
        voucherReceivers.push(receiverAdress); // only push single mailadres
      }

      var schedule_work_url = ''
      if (this.currentVoucher.schedule_workplace == 1) {
        schedule_work_url = this.httpProtocol + window.location.host; // base url
        schedule_work_url += "/werkplaatsplanner?vId=" + this.currentVoucher.voucher_id;
        schedule_work_url += "&vAanhef=" + this.aanhef;
        schedule_work_url += "&vInit=" + this.voorletters;
        schedule_work_url += "&vNaam=" + this.achternaam;
        schedule_work_url += "&vKenteken=" + this.kenteken;
        if (this.telefoonnummer != '') {
          schedule_work_url += "&vTelnr=" + this.telefoonnummer;
        }
        schedule_work_url += "&vEmail=" + this.email;
        if (this.toestemmingOfferte == true) {
          schedule_work_url +="&vtoestemmingOfferte=" + this.toestemmingOfferte;
        }
      }
      var bodyFormData = {
        form_data: JSON.stringify(
            {
              'aanhef': this.aanhef,
              'voorletters': this.voorletters,
              'achternaam': this.achternaam,
              'telefoonnummer': this.telefoonnummer,
              'kenteken': this.kenteken,
              'email': this.email,
              'offerte tekst': this.invoiceNote
            }
        ),
        confirmation_mail: JSON.stringify(
            {
              "recipients": voucherReceivers, // get mailadress(es)
              "to": voucherReceivers,
              "from": this.dealerJSON.config.mail_sender_name + " <" + this.dealerJSON.config.mail_sender_email + ">",
              "subject": this.dealerJSON.config.mail_subject,
              "template": 2,
              "images": "hosted",
              "data": {
                "aanhef": this.aanhef.toLowerCase(),
                "voorletters": this.voorletters,
                "achternaam": this.achternaam,
                "email": this.email,
                "telefoonnummer": this.telefoonnummer,
                "kenteken": this.kenteken,
                "toestemmingOfferte": this.toestemmingOfferte,
                "offerte_text": this.currentVoucher.quotation_text,
                "offerte_text_client": this.invoiceNote,
                "voucher_only": 'true',
                "dealer_name": this.dealerJSON.config.mail_full_dealer_name,
                "establishments": this.dealerJSON.config.dealer_establishments,
                "mail_plan_it_intro_text": dealerConfirmMesg,
                "mail_voucher_intro_text": this.dealerJSON.config.mail_voucher_intro_text,
                "mail_thank_you_message": this.dealerJSON.config.mail_thank_you_message,
                "logo_size": this.dealerConfigData.dealer_logo.email_size,
                "voucher_icon": this.httpProtocol + this.currentVoucher.voucher_icon,
                "voucher_header": this.currentVoucher.voucher_header,
                "voucher_description": this.currentVoucher.voucher_description,
                "voucher_text_color": this.getVoucherColor(this.currentVoucher)[1],
                "voucher_background_color": this.getVoucherColor(this.currentVoucher)[0],
                "general_terms": this.currentVoucher.general_terms,
                "schedule_work_url": schedule_work_url,
                "schedule_work_label": this.dealerJSON.config.mail_plan_it_button_text,
                "schedule_work_button_bgcolor": this.getVoucherColor(this.currentVoucher)[0],
                "schedule_work_button_textcolor": this.getVoucherColor(this.currentVoucher)[1]
              }
            }
        ),
        dealer_id: this.dealerJSON.config.dealer_id,
        voucher_id: this.currentVoucher.voucher_id,
        dealer_confirmation_mail: isDealerMail,
        guid: this.uuid
      }
      axios.post(
          this.dealerConfigData.api.url + this.dealerConfigData.api.dealer + this.dealerConfigData.api.voucherSubmissionURL,
          bodyFormData,
          this.apiConfig
      ).then((response) => {
        // perform end form actions when parameter of method is true
        if (endFormAction == true) {
          this.currentStep = 3; // go to finish page
          window.scrollTo(0,0);
        }
        return response; // eslint will throw an error if you do not use variable
      }).catch((error) => {
        // perform end form actions when parameter of method is true
        if (endFormAction == true) {
          this.showErrorMesg = true; // Show alert in form, when the form does not pass validation.
          this.errorMesg = "Er is iets misgegaan met het verzenden van de voucher, neem contact op met uw dealer";
        }
        return error; // eslint will throw an error if you do use variable
      })
    },
  }
};
</script>

<style lang="scss">
// START Sass variables
$voucherAnimWidth:370px;
$voucherWidth:250px;
// END Sass variables

#VoucherForm {
  position: relative;
  z-index: 5;
  height: 100%;
}
#formBG {
  position: relative;
  z-index: 1;
  background-color: red;
}
#VoucherForm,
.voucherBackgroundColor {
  background-color: #eeeeee !important;
}
.kenteken--error label {
  color: red !important;
}
// animation for final step on voucher form, showing completion animation
@keyframes goDown {
  0% {transform:translateY(0);}
  40% {transform:translateY(0);}
  100% {transform:translateY(100%);}
}
// animation for final step on voucher form, showing completion animation
@keyframes becomeInvisible {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
// animation for final step on voucher form, showing completion animation
@keyframes becomeVisible {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
// animation for final step on voucher form, showing completion animation
@keyframes disableWrapper {
  0%{height:100%;}
  50%{height:200px;}
  100% {height:70px;}
}
.max-voucher-width {
  max-width: $voucherWidth;
}
.animWrap {
  position: relative;
  width: $voucherAnimWidth;
  .voucherAnimWrap {
    position: relative;
    z-index: 6;
    overflow: hidden;
    margin-bottom: -124px;
    animation-name: disableWrapper;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    animation-duration: 2s;
    .voucherAnimWrap-inner {
      width: $voucherWidth;
      margin: 0 auto;
      overflow: hidden;
      animation-name: goDown;
      animation-fill-mode: forwards;
      animation-duration: 2.5s;
    }
  }
  .iconFinish {
    position: absolute;
    bottom: 20%;
    right: 10%;
    font-size: $voucherAnimWidth / 5;
    opacity: 0;
    animation-name: becomeVisible;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    animation-duration: 2s;
  }
  .iconHelper,
  .iconBottom,
  .iconTop {

  }
  .iconHelper,
  .iconBottom {
    animation-name: becomeInvisible;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    animation-duration: 2s;
  }
  .iconTop {
    position: absolute;
    opacity: 0;
    font-size: $voucherAnimWidth;
    animation-name: becomeVisible;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
    animation-duration: 2s;
  }
  .iconBottom {
    position: absolute;
    z-index: 1;
    font-size: $voucherAnimWidth;
  }
  .iconHelper {
    position: relative;
    opacity: 0;
    font-size: $voucherAnimWidth;
  }
}
.hide-voucher-text {
  opacity: 0;
  animation-name: becomeVisible;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  animation-duration: 2s;
}
.voucherButton-wrapper.step-1 {
  > * {
    float: left;
    clear: both;
  }
}
.aanhef-field {
  .v-input__slot {margin-bottom: 6px!important;}
}
.voucherField {
  width: 100%;
  padding-left: 8px !important;
  padding-right: 8px !important;
  &.v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: rgba(255,255,255,1);
  }
}
.voucher-stepper-header {
  max-width: 900px;
}
.intro-wrapper,
.voucher-preview-header,
.form-wrapper {
  max-width: 1160px;
}
.voucher-stepper-header,
.intro-wrapper,
.voucher-preview-header,
.form-wrapper {
  width: 100%;
  margin: 0 auto;
}
.voucher-preview {
  position: relative;
  //margin: 0 auto;
  &.step-one-desk {
    padding-right: 22px;
  }
}
.voucher-form {
  position: relative;
  &.voucher-form-step1 {
    max-width: 680px;
    width: 100%;
  }
  &.voucher-form-step2 {
    //width: 100%;
  }
}
.voucher-kenteken input {
  text-transform: uppercase;
}
.voucherField-wrapper {
  padding-bottom: 10px;
}
.bevestigVoucherGegevens {
  tr:hover,td:hover {
    background-color: transparent!important;
  }
  td {
    border-bottom: none!important;
    font-size:16px !important;
    vertical-align: top;
    height: auto !important;
    padding-top: 10px;
  }
}
@media only screen and (max-width:599px) {
  .voucherButton-wrapper {
    flex-direction: column-reverse;
    button.voucherButton {
      margin: 0 auto 16px !important;
      width: 100%;
    }
  }
}
@media only screen and (max-width:680px) and (orientation: portrait) {
  // Tablet in portrait not bigger than 620px reso
  .v-application div.intro-wrapper {
    flex-wrap: wrap !important;
  }
  .v-application .voucher-form {
    &.voucher-form-step2,
    &.voucher-form-step3 {
      padding: 0 !important;
    }
  }
}
@media only screen and (max-width:767px) {
  .mobVoucher2Class {
    width: 100%;
    .voucherWerkplannerButton {
      white-space: normal !important;
      min-height: 50px !important;
    }
  }
  .voucher-preview {
    position: relative;
    //margin: 0 auto;
    &.step-one-desk {
      padding-right: 20px !important;
    }
  }
}
// MAX WIDTH 800px
@media only screen and (max-width:800px) {
  .voucher-form-step1 {
    .voucherField-wrapper {
      padding-bottom: 0 !important;
    }
    .voucherField-wrapper .voucherField {
      padding-bottom: 10px !important;
    }
  }
}
// MIN WIDTH 800px
@media only screen and (min-width:800px) {
  .v-application div.voucherField-wrapper {
    flex-wrap: nowrap!important;
  }
}
</style>
