<template>
  <div id="download-wrapper">
    <!-- Voucher Form -->
    <VoucherForm v-if="currentVoucher" :currentVoucher="currentVoucher" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners"/>
  </div>
</template>

<script>
import VoucherForm from '@/components/voucherForm/form.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'download',
  components: {
    VoucherForm
  },
  metaInfo() {
    return {
      title: this.currentVoucher.voucher_header + ' ' + this.currentVoucher.voucher_description
    }
  },
  computed: {
    currentVoucher() {
      if (this.dealerJSON) {
        var _vue = this;
        return this.dealerJSON.data.find( voucher => voucher.voucher_id === parseInt(_vue.$route.meta.voucher_id));
      }
      else {
        return 'nothing';
      }
    },
    ...mapState({
      dealerJSON: state => state.dealerJSON // get data from store
    })
  }
}
</script>